import { RefObject, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { motion } from 'framer-motion';

import './index.css';

export default function ScrollIndicator(props: {
  scrollContainerRef: RefObject<HTMLElement>;
}) {
  const [visible, setVisibility] = useState<boolean>(true);

  useEffect(() => {
    if (!props.scrollContainerRef.current) return;

    const handler = (ev: Event) => {
      const node = ev.target as HTMLDivElement;
      if (!node) return;
      const hide =
        node.scrollHeight - node.scrollTop >= node.clientHeight + 200;
      setVisibility(hide);
    };

    const throttleHandler = debounce(handler, 100);

    props.scrollContainerRef.current.addEventListener(
      'scroll',
      throttleHandler,
    );

    return () => {
      if (props.scrollContainerRef.current) {
        props.scrollContainerRef.current.removeEventListener(
          'scroll',
          throttleHandler,
        );
      }
    };
  }, [props.scrollContainerRef]);

  return (
    <motion.div
      className="fixed bottom-[20px] left-[50%] z-50 h-[70px] w-[50px]"
      initial={{
        transform: visible ? 'translate(-50%, 200%)' : 'translate(-50%, 0%)',
      }}
      animate={{
        transform: visible ? 'translate(-50%, 0%)' : 'translate(-50%,200%)',
      }}
      transition={{
        duration: 0.3,
        ease: 'linear',
      }}
      onClick={() => {
        props.scrollContainerRef.current?.scrollBy({
          top: window.innerHeight - 100,
          behavior: 'smooth',
        });
      }}
    >
      <div className="indicator">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </motion.div>
  );
}
