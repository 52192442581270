import { useEffect, useState } from 'react';
import {
  createUserUploadEditor,
  generateDefaultShareImage,
} from '../../services/ShareImageGenerator';
import ImageUploader from './ImageUploader';
import { loadImageAsync } from '../../services/ImageLoader';
import { ProductGroup } from '../../types/Product';
import CondomButton from './CondomButton';

export default function YourTeamAndShare(props: {
  resultProductGroup: ProductGroup;
  randomDefaultSizeImgPath: string;
}) {
  // const canvasContainerId = `sharable-canvas-container`;
  // const canvasId = `sharable-canvas`;

  // const [uploadableCanvasInstance, setUploadableCanvasInstance] =
  //   useState<Awaited<ReturnType<typeof createUserUploadEditor>>>();

  // useEffect(() => {
  //   const genCanvas = async () => {
  //     const defaultBgImg = await loadImageAsync('/img/square.jpg');
  //     const defaultSizeImg = await loadImageAsync('/img/cards/45.png');

  //     const instance = await createUserUploadEditor(
  //       canvasContainerId,
  //       canvasId,
  //       defaultBgImg,
  //       defaultSizeImg,
  //     );

  //     instance.fitStageIntoParentContainer();

  //     setUploadableCanvasInstance(instance);
  //   };

  //   genCanvas();
  // }, []);

  // useEffect(() => {
  //   if (!uploadableCanvasInstance) {
  //     return;
  //   }

  //   window.addEventListener(
  //     'resize',
  //     uploadableCanvasInstance.fitStageIntoParentContainer,
  //   );

  //   return () => {
  //     window.removeEventListener(
  //       'resize',
  //       uploadableCanvasInstance.fitStageIntoParentContainer,
  //     );

  //     uploadableCanvasInstance.destroy();
  //   };
  // }, [uploadableCanvasInstance]);

  const [shareImageDataUrl, setShareImage] = useState<string>();
  const [userUploadImageDataUrl, setUserUploadImageDataUrl] =
    useState<string>();

  const defaultBgImgPath = '/img/cards/default.jpg';

  useEffect(() => {
    const genShareImg = async () => {
      const defaultBgImg = await loadImageAsync(defaultBgImgPath);
      const defaultSizeImg = await loadImageAsync(
        props.randomDefaultSizeImgPath,
      );
      const generatedShareImage = await generateDefaultShareImage(
        defaultBgImg,
        defaultSizeImg,
        userUploadImageDataUrl,
      );
      setShareImage(generatedShareImage);
    };

    genShareImg();
  }, [userUploadImageDataUrl]);

  return (
    <>
      {/* <div id={canvasContainerId} className="relative">
        <div id={canvasId}></div>
      </div> */}
      {shareImageDataUrl ? (
        <img className="px-6" src={shareImageDataUrl} alt="share" />
      ) : null}
      <ImageUploader
        className="mt-8 block min-h-[12dvh] min-w-full bg-insert-your-photo bg-contain bg-center bg-no-repeat active:bg-insert-your-photo-click"
        onImageReady={(dataUrl) => {
          setUserUploadImageDataUrl(dataUrl);
        }}
      />
      <a
        className="mt-4 block min-h-[12dvh] min-w-full bg-download-share-img bg-contain bg-center bg-no-repeat active:bg-download-share-img-click"
        href={shareImageDataUrl}
        download
        title="share"
      ></a>
    </>
  );
}
