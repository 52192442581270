import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

const sizes = [25, 26, 27, 28, 29, 30];
const numberSize = 110;

export default function LoadingAnimation(props: { onCompleted: () => void }) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // try {
      //   fetch('https://aixasz.com/api/GlowProject/RecordTracking', {
      //     method: 'POST',
      //     body: JSON.stringify({
      //       project: 'watjuu',
      //       userAgent: window.navigator.userAgent,
      //       data: '',
      //       state: 'load-home-page',
      //     }),
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   });
      // } catch (error) {}

      props.onCompleted();
    }, 5000);
    
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <motion.div
      className="loading-animation-bg flex items-center justify-center"
      style={{
        position: 'relative',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
      initial={{
        width: '240px',
        height: '240px',
      }}
      animate={{
        width: '340px',
        height: '340px',
        opacity: [1, 1, 0],
      }}
      transition={{
        ease: 'linear',
        duration: 4.9,
      }}
    >
      <AnimatePresence initial={false} mode="wait">
        {sizes.map((s, i) => {
          return (
            <motion.img
              key={s}
              src={`/img/home/onetouch-${s}.png`}
              animate={{
                opacity: [0, 1, s === 30 ? 1 : 0],
                y: 0,
              }}
              transition={{ duration: 0.7, delay: i * 0.7 }}
              style={{
                position: 'absolute',
                width: `${numberSize}px`,
                height: `${numberSize}px`,
                top: `-${numberSize / 2}px`,
                left: `-${numberSize / 2}px`,
                marginLeft: `50%`,
                marginTop: '50%',
              }}
            />
          );
        })}
      </AnimatePresence>
    </motion.div>
  );
}
