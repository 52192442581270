import { useEffect } from 'react';
import { createMemoryRouter, RouterProvider, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MeasurePage from './pages/MeasurePage';
import { DesktopPage } from './pages/DesktopPage';
import { appInsightsService } from './services/appInsightsService';

const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

const HorizontalGuard = () => (
  <div id="horizontal-guard">กรุณาปรับหน้าจอเป็นแนวตั้งขณะใช้งานเว็บไซต์</div>
);



const router = createMemoryRouter(
  [
    {
      path: '/',
      element: (
        <>
          <HomePage />,
          <HorizontalGuard />
        </>
      ),
    },
    {
      path: 'measure',
      element: (
        <>
          <MeasurePage />,
          <HorizontalGuard />
        </>
      ),
    },
    {
      path: 'desktop',
      element: <DesktopPage />,
    },
  ],
  {
    initialEntries: [isMobileDevice() ? '/' : '/desktop'],
    initialIndex: 0,
  },
);

function App() {
  // Initialize App Insights only once at the App component level
  useEffect(() => {
    appInsightsService.logPageView("App Loaded");
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
