import { useContext, useEffect, useRef, useState } from 'react';
import { MeasureContext } from '../../contexts/MeasureContext';
import { useWizard } from 'react-use-wizard';

import ProductCarousel from '../commons/ProductCarousel';
import YourTeamAndShare from '../commons/YourTeamAndShare';
import ProductSizeSelect from '../commons/ProductSizeSelec';
import { products } from '../../data/series';
import CondomButton from '../commons/CondomButton';
import ScrollIndicator from '../commons/ScrollIndicator';
import { logResult } from '../../services/logger';
import { random } from 'lodash';
import { appInsightsService } from '../../services/appInsightsService';

export default function ChooseSize() {
  const { goToStep } = useWizard();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { state, setProduct, setHisSizeIndex, setProgress } =
    useContext(MeasureContext);

  const [radd] = useState<number>(random(1, 3, false));

  useEffect(() => {
    setProgress(100);
    logResult(window.navigator, state);
    appInsightsService.logEvent("ChoosedSize", {
      userAgent: navigator.userAgent,
      size: state.hisProductGroup?.size,
    });
  }, []);

  return (
    <div
      className="overflow-y-auto overflow-x-hidden bg-main-black"
      ref={scrollContainerRef}
    >
      {state.hisProductGroup ? (
        <div className="summary-bg">
          <div className="summary-share-image-bg container flex flex-col items-center py-20">
            <div className="mt-10">
              <YourTeamAndShare
                resultProductGroup={state.hisProductGroup}
                randomDefaultSizeImgPath={`/img/cards/${state.hisProductGroup.size}/${radd}.png`}
              />
            </div>
          </div>
          <div className="container relative mt-10">
            <div className="mb-8 text-center text-4xl font-bold text-main-white">
              ไซซ์ที่ใช่ของคุณคือ?
            </div>
            <ProductSizeSelect
              options={products.map((s, index) => ({
                value: `${s.size}`,
                label: `${s.size}`,
                hisSizeIndex: index,
              }))}
              selected={{
                value: `${state.hisProductGroup.size}`,
                label: `${state.hisProductGroup.size}`,
                hisSizeIndex: state.hisSizeIndex,
              }}
              color={`#${state.hisProductGroup.themeColor}`}
              onChanged={(o) => {
                setHisSizeIndex(o.hisSizeIndex);
              }}
            />
            <ProductCarousel
              productGroup={state.hisProductGroup}
              color={state.hisProductGroup.themeColor}
              onChanged={(currentProduct) => {
                setProduct(currentProduct);
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="container mt-20">
        <div className="text-center text-xl font-light text-main-yellow">
          45|49|52|54|56|60
        </div>
        <div className="mt-4 text-center text-4xl font-bold text-main-white">
          จะไซซ์ไหนก็ใส่
          <br />
          ONETOUCH
        </div>
        <img
          src="/img/measure/summary/pack.png"
          alt="pack"
          className="block w-full px-2"
        />
      </div>
      <div className="container">
        <CondomButton
          className="!min-h-[21dvh] bg-measure-again active:bg-measure-again-click"
          warningText={
            <div className="relative bottom-[-100%] mx-auto w-[90%] translate-y-[100%] rotate-[-7deg] text-center text-2xl font-light text-main-yellow">
              หากยังไม่เชื่อสายตาตัวเอง
            </div>
          }
          onClick={() => {
            goToStep(0);
          }}
        />
      </div>
      <div className="container mt-14 p-4">
        <div className="rounded-3xl border border-main-yellow p-6 py-16 ">
          <div className="text-center text-4xl font-bold text-amber-500">
            DISCLAIMER
          </div>
          <div className="mt-10 text-center text-2xl font-light text-white">
            การวัดผ่านมือถืออาจคลาดเคลื่อน
            <br />
            เนื่องจากขนาดของโทรศัพท์
            <br />
            ลองเพิ่มความมั่นใจด้วยวิธีการอื่นๆ ดังนี้
          </div>
          <div className="mt-10 text-center text-xl font-bold text-white">
            1.ใช้สายวัดตัว
          </div>
          <div className="mt-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qMXasDZEdOs"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{
                aspectRatio: '0.8',
                width: '100%',
              }}
            ></iframe>
          </div>
          <div className="text mt-4 text-center text-white">
            วิธีวัดขนาดถุงยาง นำค่าความยาวเส้นรอบอวัยวะเพศ (มม.)
            <br />
            มาหารด้วย 2 เพื่อหาขนาดถุงยางที่กระชับและ
            <br />
            สวมใส่สบายที่สุดของแต่ละคน​
          </div>
          <div className="mt-10 text-center text-xl font-bold text-white">
            2.ดาวน์โหลดสายวัดจู๋
          </div>
          <div className="mt-4 bg-main-white px-4 py-8">
            <img
              className="block w-full"
              src="/img/measure/summary/ju-measure-tape.png"
              alt="สายวัดจู๋"
            />
          </div>
          <div className="text mt-4 text-center">
            <span className="text-main-yellow">ได้ที่</span>
            <br />
            <a
              className="text-main-yellow underline"
              href="https://resourcecenter.thaihealth.or.th/files/66/AW%20%E0%B8%AA%E0%B8%B2%E0%B8%A2%E0%B8%A7%E0%B8%B1%E0%B8%94%E0%B8%88%E0%B8%B9%E0%B9%8B%20(60-07-21)-2.pdf"
            >
              https://resourcecenter.thaihealth.or.th
            </a>
            <span className="text-main-white">
              <br />
              แบบพิมพ์ใส่กระดาษ โดยกลุ่มโรคเอดส์กรมควบคุมโรค
              <br />
              กระทรวงสาธารณสุขแห่งประเทศไทย​
              <br />
              สั่งพิมพ์โดยตั้งค่า Page Scaling เป็น none
              <br />
              (หรือ การพิมพ์แบบไร้ขอบ) เพื่อสเกลที่แม่นยำมากที่สุด​
              <br />
              พิมพ์แล้ว ตัด วัดได้เลย!
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mt-20 text-center">
          <div className="text-4xl font-bold leading-tight text-main-white">
            ใส่ผิดไซซ์
            <br />
            เพิ่มสิทธิ์ท้อง
            <br />
            น้องชายหดตัว<sup className="text-main-yellow">*</sup>
          </div>
          <div className="mt-2 text-xl font-light text-main-yellow">
            #SizeMatter
          </div>
          <div className="text-xl font-light text-main-yellow">
            #ไซซ์ที่ใช่จัดเลยที่ONETOUCH
          </div>
          <div className=" mt-2 text-xl text-main-yellow">
            <sup>*</sup>การใช้ถุงยางอนามัยที่ไม่เหมาะสมอาจทำให้
            <br />
            สูญเสียการแข็งตัวของน้องชายได้ถึง 2 เท่า
          </div>
        </div>
        <a href="/" target="_blank" className="mt-10 block w-full">
          <img
            className="block w-full"
            src="/img/measure/summary/pack.png"
            alt="อวสานถุงยางผิดไซซ์"
          />
        </a>
      </div>
      <ScrollIndicator scrollContainerRef={scrollContainerRef} />
    </div>
  );
}
