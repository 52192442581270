export function DesktopPage() {
  return (
    <div className="desktop-bg flex h-full w-full flex-col items-center justify-center gap-8">
      <div className="w-5/12 lg:w-3/12">
        <img src="/img/ui-desktop-3.png" />
      </div>
      <div className="w-3/12 lg:w-1/12">
        <img src="/img/home/onetouch-32.png" />
      </div>
    </div>
  );
}
