const inchInMm = 25.4; // 1 inch = 25.4 mm
const physicalIdCardHeightInInches = 2.12598;
const idCardRatio = 1.6;

// export const mmToPx = (
//   expectedMillimeter: number,
//   comparerHeightInPixel: number,
// ) => {
//   const ppi = Math.floor(comparerHeightInPixel / physicalIdCardHeightInInches);
//   console.log(ppi);
//   return ((expectedMillimeter / inchInMm) * ppi) / window.devicePixelRatio;
// };

/**
 * id card height in display pixel = height in CSS pixel * devicePixelRatio;
 *
 * pixelDesity = id card height in display pixel / id card measured in inch
 * 268 = (285 * 2) / 2.12598
 *
 * 1 inch = 233px
 * 1 inch = 25.4mm
 * pixel per mm 9px = 233 / 25.4
 * 50mm is 264px = 50 * 5.28
 */
export const mmToPx = (
  expectedMillimeter: number,
  comparerHeightInCssPixel: number,
) => {
  const comparerHeightInDisplayPixel =
    comparerHeightInCssPixel * window.devicePixelRatio;

  const displayPixelPerInch = Math.ceil(
    comparerHeightInDisplayPixel / physicalIdCardHeightInInches,
  );

  const displayPixelPerMm = Math.ceil(displayPixelPerInch / inchInMm);

  const displayPixelForExpectedMillimeter = Math.ceil(
    expectedMillimeter * displayPixelPerMm,
  );

  const cssPixelForExpectedMillimeter =
    displayPixelForExpectedMillimeter / window.devicePixelRatio;

  console.log({
    expectedMillimeter,
    comparerHeightInDisplayPixel,
    comparerHeightInCssPixel,
    displayPixelPerInch,
    displayPixelPerMm,
    displayPixelForExpectedMillimeter,
    cssPixelForExpectedMillimeter,
  });

  return cssPixelForExpectedMillimeter;
};

export const toIdCardWidth = (h: number) => Math.floor(h * idCardRatio);
