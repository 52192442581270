// appInsightsService.ts
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

const config = {
  instrumentationKey: '7dd32b94-1f05-41e7-9ef9-b1dd3fb67e29',
  // ...other configurations
};

class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({ config });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope) => {
        if (envelope.tags) {
            envelope.tags['ai.user.userAgent'] = navigator.userAgent;
        }     
    });
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({ name, uri: url });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name, properties });
  }
}

export const appInsightsService = new AppInsightsService();
