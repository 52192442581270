import { useContext, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { MeasureContext } from '../../contexts/MeasureContext';
import VerticalSlider from './../commons/VerticalSlider';
import CondomButton from '../commons/CondomButton';
import { animate } from 'framer-motion';

export default function MeasureCard() {
  const { state, setProgress, setCardHeight } = useContext(MeasureContext);
  const { nextStep } = useWizard();

  useEffect(() => {
    setProgress(25);
  }, []);

  useEffect(() => {
    const controlUp = animate(250, 300, {
      type: 'spring',
      damping: 6,
      stiffness: 100,
      restDelta: 1,
      delay: 0.6,
      onUpdate(h) {
        const next = parseInt(h.toFixed(0));
        setCardHeight(next);
      },
    });

    return () => {
      controlUp.stop();
    };
  }, []);

  return (
    <div className="main-bg h-full-exclude-progress">
      <div className="h-full py-4">
        <div className="container relative flex h-full flex-col justify-between bg-black-wide-condom bg-contain bg-center bg-no-repeat">
          <div className="mt-16 flex-grow-0 ">
            <div className="text-center text-xl">
              <b className="text-main-yellow">ทาบบัตรลงบนหน้าจอ</b>
            </div>
            <div className="text-center text-xl">
              <b className="text-main-yellow">และรูดดดที่แถบด้านขวา</b>
              <br />
              <span className="text-xl font-light text-main-yellow">
                ให้ภาพจำลองบัตรมีขนาดเท่ากัน
                <br />
                กับของจริง
              </span>
            </div>
          </div>
          <div className="absolute mx-[-16px] flex h-full w-full flex-grow items-center justify-between overflow-hidden">
            <div
              className={`id-card left--56`}
              style={{
                width: `${state.cardDimension.w}px`,
                height: `${state.cardDimension.h}px`,
                marginLeft: `-${state.cardDimension.w * 0.6}px`,
              }}
            ></div>
            <VerticalSlider
              value={state.cardDimension.h}
              min={200}
              max={430}
              onChange={(px) => setCardHeight(px)}
            />
          </div>
          <div className="z-50 flex-grow-0">
            <CondomButton
              className="!min-h-[16vh] bg-calibrated active:bg-calibrated"
              onClick={() => {
                nextStep();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
