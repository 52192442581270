import { ButtonHTMLAttributes } from 'react';

export default function CondomButton(
  props: ButtonHTMLAttributes<HTMLButtonElement> & {
    warningText?: React.ReactNode;
    onClick: () => void;
  },
) {
  return (
    <div>
      {props.warningText}
      <button
        type="button"
        onClick={props.onClick}
        title="condom button"
        className={`block min-h-[16vh] min-w-full bg-contain bg-center bg-no-repeat ${props.className}`}
      ></button>
    </div>
  );
}
