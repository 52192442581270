import Konva from 'konva';
import { loadImageAsync } from './ImageLoader';

Konva.hitOnDragEnabled = true;

type ShareImageVariantType = {
  horizontal: string;
  vertical: string;
};

export type GenerateShareImagesReturnType = {
  forShareDataUrl: ShareImageVariantType;
  forUseWithUserUploadImageDataUrl: ShareImageVariantType;
};

export const generateDefaultShareImage = async (
  defaultBgImg: HTMLImageElement,
  defaultSizeImg: HTMLImageElement,
  userUploadedImgDataUrl?: string,
) => {
  const container = document.createElement('div');

  if (!container) {
    return Promise.reject('Element for canvas not found!');
  }

  const originalWidth = defaultSizeImg.width;
  const originalHeight = defaultSizeImg.height;

  const stage = new Konva.Stage({
    width: originalWidth,
    height: originalHeight,
    container,
  });

  const bgLayer = new Konva.Layer();
  bgLayer.add(
    // new Konva.Image({
    //   image: defaultBgImg,
    //   x: stage.width() / 2 - defaultBgImg.width / 2,
    //   y: stage.height() / 2 - defaultBgImg.height / 2,
    //   width: defaultBgImg.width,
    //   height: defaultBgImg.height,
    //   listening: false,
    // }),
    processUploadImage(defaultBgImg, originalWidth, originalHeight),
  );

  const productSizeLayer = new Konva.Layer();
  const productSizeScale = 1;
  productSizeLayer.add(
    new Konva.Image({
      image: defaultSizeImg,
      x: 0,
      y: 0,
      width: defaultSizeImg.width * productSizeScale,
      height: defaultSizeImg.height * productSizeScale,
      listening: false,
    }),
  );

  const userUploadImageLayer = new Konva.Layer();
  if (userUploadedImgDataUrl) {
    const userUploadedImg = await loadImageAsync(userUploadedImgDataUrl);

    userUploadImageLayer.add(
      processUploadImage(userUploadedImg, originalWidth, originalHeight),
    );
  }

  stage.add(bgLayer);
  stage.add(userUploadImageLayer);
  stage.add(productSizeLayer);

  const dataUrl = stage.toDataURL({ mimeType: '', pixelRatio: 1 });

  stage.destroy();
  return dataUrl;
};

export const createUserUploadEditor = async (
  containerParentElementId: string,
  containerElementId: string,
  defaultBgImg: HTMLImageElement,
  defaultSizeImg: HTMLImageElement,
) => {
  const container = document.getElementById(
    containerElementId,
  ) as HTMLDivElement;

  if (!container) {
    return Promise.reject('Element for canvas not found!');
  }

  const originalWidth = defaultSizeImg.width;
  const originalHeight = defaultSizeImg.height;

  const stage = new Konva.Stage({
    width: originalWidth,
    height: originalHeight,
    container,
    id: containerElementId,
  });

  const bgLayer = new Konva.Layer();
  bgLayer.add(
    new Konva.Image({
      image: defaultBgImg,
      x: stage.width() / 2 - defaultBgImg.width / 2,
      y: stage.height() / 2 - defaultBgImg.height / 2,
      width: defaultBgImg.width,
      height: defaultBgImg.height,
      listening: false,
    }),
  );

  const productSizeLayer = new Konva.Layer();
  const productSizeScale = 1;
  productSizeLayer.add(
    new Konva.Image({
      image: defaultSizeImg,
      x: 0,
      y: 0,
      width: defaultSizeImg.width * productSizeScale,
      height: defaultSizeImg.height * productSizeScale,
      listening: false,
    }),
  );

  const userUploadImageLayer = new Konva.Layer();

  stage.add(bgLayer);
  stage.add(userUploadImageLayer);
  stage.add(productSizeLayer);

  const addUserImage = async (uploadedImageDataUrl: string) => {
    userUploadImageLayer.removeChildren();

    const uploadedImage = await loadImageAsync(uploadedImageDataUrl);
    addUserImageFromHtmlImageElement(uploadedImage);
  };

  const addUserImageFromHtmlImageElement = (image: HTMLImageElement) => {
    userUploadImageLayer.removeChildren();
    let diffRatio = 0;

    const diff = image.width - originalWidth;
    diffRatio = 1 - diff / image.width;

    // console.log(
    //   'height',
    //   image.height,
    //   originalHeight,
    //   image.height - originalHeight,
    //   diffRatio,
    //   image.height * diffRatio,
    // );

    // console.log(
    //   'width',
    //   image.width,
    //   originalWidth,
    //   image.width - originalWidth,
    //   diffRatio,
    //   image.width * diffRatio,
    // );

    const finalWidth = image.width * diffRatio;
    const finalHeight = image.height * diffRatio;

    const userImage = new Konva.Image({
      image,
      x: originalWidth / 2 - finalWidth / 2,
      y: originalHeight / 2 - finalHeight / 2,
      width: finalWidth,
      height: finalHeight,
      draggable: true,
    });

    userUploadImageLayer.add(userImage);
  };

  const removeUserImage = () => {
    userUploadImageLayer.removeChildren();
  };

  const fitStageIntoParentContainer = () => {
    const container = document.getElementById(
      containerParentElementId,
    ) as HTMLDivElement;

    if (!container) {
      return;
    }

    // but we also make the full scene visible
    // so we need to scale all objects on canvas
    const scale = container.offsetWidth / originalWidth;

    stage.width(originalWidth * scale);
    stage.height(originalHeight * scale);
    stage.scale({ x: scale, y: scale });
  };

  const toDataUrl = () => {
    const dataUrl = stage
      .scale({ x: 1, y: 1 })
      .size({
        width: originalWidth,
        height: originalHeight,
      })
      .toDataURL({ pixelRatio: 1 });

    fitStageIntoParentContainer();

    return dataUrl;
  };

  const destroy = () => {
    stage.destroy();
  };

  return {
    addUserImage,
    addUserImageFromHtmlImageElement,
    removeUserImage,
    fitStageIntoParentContainer,
    toDataUrl,
    destroy,
  };
};

const processUploadImage = (
  image: HTMLImageElement,
  originalWidth: number,
  originalHeight: number,
): Konva.Image => {
  const diff = image.width - originalWidth;
  const diffRatio = 1 - diff / image.width;

  // console.log(
  //   'height',
  //   image.height,
  //   originalHeight,
  //   image.height - originalHeight,
  //   diffRatio,
  //   image.height * diffRatio,
  // );

  // console.log(
  //   'width',
  //   image.width,
  //   originalWidth,
  //   image.width - originalWidth,
  //   diffRatio,
  //   image.width * diffRatio,
  // );

  const widthAfterRatio = image.width * diffRatio;
  const heightAfterRatio = image.height * diffRatio;

  const adjustedWidth = widthAfterRatio * 0.8;
  const adjustedHeight = heightAfterRatio * 0.8;

  const userImage = new Konva.Image({
    image: image,
    x: originalWidth / 2 - adjustedWidth / 2,
    y: originalHeight / 2 - adjustedHeight / 2,
    width: adjustedWidth,
    height: adjustedHeight,
  });

  userImage.cache();
  userImage.filters([Konva.Filters.Grayscale]);

  return userImage;
};
