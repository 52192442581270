import Select from 'react-select';
import { Option } from '../../types/Option';

export default function ProductSizeSelect(props: {
  options: Option[];
  selected: Option;
  color: string;
  onChanged: (selected: Option) => void;
}) {
  return (
    <div className="flex justify-center">
      <Select
        value={props.selected}
        onChange={(o, meta) => {
          if (o) {
            props.onChanged(o);
          }
        }}
        options={props.options}
        isSearchable={false}
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            minWidth: '70%',
            zIndex: 1000,
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: '#ddd',
            boxShadow: 'none',
            backgroundColor: '#000',
            borderRadius: '5vmax',
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            visibility: 'hidden',
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            position: 'absolute',
            right: '10%',
            top: '50%',
            transform: 'translateY(-50%) scale(2)',
            color: props.color,
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            color: '#4e4e4e',
            fontWeight: 'bold',
            backgroundColor: '#000',
            textAlign: 'center',
            borderRadius: '5vmax',
            borderColor: '#ddd',
            borderWidth: '1px',
            borderStyle: 'solid',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isSelected ? props.color : '#efefef',
            background: 'transparent',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: '#000',
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: props.color,
            fontWeight: 'bolder',
            fontSize: '5vmax',
            textAlign: 'center',
            position: 'relative',
            top: '5px',
          }),
        }}
      />
    </div>
  );
}
