import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export default function Modal(props: PropsWithChildren) {
  return createPortal(
    <div
      className="fixed inset-0 z-50 h-[100dvh] w-full bg-black/50 text-2xl font-black text-white"
      aria-hidden="true"
    >
      {props.children}
    </div>,
    document.getElementById('modal')!,
  );
}
