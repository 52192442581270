import { MeasureContextProvider } from '../contexts/MeasureContext';
import MeasureCard from '../components/Measure/MeasureCard';
import { Wizard } from 'react-use-wizard';
import MeasureHis from '../components/Measure/MeasureHis';
import ProgressBar from '../components/commons/ProgressBar';
import ChooseSize from '../components/Measure/ChooseSize';
import WakeHimUp from '../components/Measure/WakeHimUp';
import PickUpYourIdCard from '../components/Measure/PickUpYourIdCard';

export default function MeasurePage() {
  return (
    <MeasureContextProvider>
      {(state) => (
        <>
          <div className="flex h-[100dvh] flex-col overflow-hidden">
            <ProgressBar progress={state.progress} />
            <Wizard startIndex={0}>
              <PickUpYourIdCard />
              <MeasureCard />
              <WakeHimUp />
              <MeasureHis />
              <ChooseSize />
            </Wizard>
          </div>
        </>
      )}
    </MeasureContextProvider>
  );
}
