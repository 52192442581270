import { useContext, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { MeasureContext } from '../../contexts/MeasureContext';
import Modal from '../commons/Modal';
import useDisclosure from '../../hooks/useDisclosure';
import CondomButton from '../commons/CondomButton';
import { mmToPx as mtp } from '../../services/converter';

export default function MeasureHis() {
  const { state, setProgress, setHisSizeIndex } = useContext(MeasureContext);
  const { nextStep } = useWizard();
  const { isOpen, close, open } = useDisclosure();

  useEffect(() => {
    open();
    setProgress(75);
  }, []);

  const getFromColor = () => `from-${state.hisProductGroup?.themeColor}`;

  function mmToPx(mm: number) {
    return mtp(mm, state.cardDimension.h);
  }

  return (
    <>
      <div className={`bg-gradient-to-t ${getFromColor()} to-transparent`}>
        <div className="measure-his-bg container relative flex h-full-exclude-progress flex-col gap-4">
          <div className="flex flex-grow justify-center gap-5 pt-16">
            <RoundedYellowButton
              text="-"
              disabled={state.hisSizeIndex === state.minHisSizeIndex}
              onClick={() => {
                setHisSizeIndex(state.hisSizeIndex - 1);
              }}
            />
            <button
              className="h-[70px] w-[70px] rounded-full border-4 border-double border-main-yellow bg-main-black p-2 text-3xl font-bold leading-[0] text-main-yellow"
              onClick={() => nextStep()}
            >
              OK
            </button>
            <RoundedYellowButton
              text="+"
              disabled={state.hisSizeIndex === state.maxHisSizeIndex}
              onClick={() => {
                setHisSizeIndex(state.hisSizeIndex + 1);
              }}
            />
          </div>
          <div
            className="relative flex flex-grow-0 flex-col"
            style={{ height: mmToPx(110.4) }}
          >
            <div
              className="absolute bg-ju-outline bg-contain bg-top bg-no-repeat"
              style={{
                width: mmToPx(42.04),
                height: mmToPx(110.4),
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            ></div>
            {state.hisProductGroup ? (
              <div
                className="absolute bg-ju bg-cover bg-no-repeat"
                style={{
                  width: mmToPx(state.hisProductGroup.widthInMillimeter),
                  height: mmToPx(state.hisProductGroup.heightInMillimeter),
                  top: `${
                    mmToPx(110.4) -
                    mmToPx(state.hisProductGroup.heightInMillimeter)
                  }px`,
                  left: `calc(50% - ${
                    (mmToPx(42.04) -
                      mmToPx(state.hisProductGroup.widthInMillimeter)) /
                    2
                  }px)`,
                  transform: 'translateX(-50%)',
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
      {isOpen ? (
        <Modal>
          <div className="flex h-[100dvh] w-full">
            <div className="measure-warning-modal-bg flex h-[52vh] flex-grow flex-col justify-end self-end">
              <div className="mt-8 flex-grow pt-20 text-center">
                <b className="text-3xl font-bold text-main-yellow">
                  ทาบ-ปรับ ให้เป๊ะ!
                </b>
                <br />
                <span className="text-xl font-light text-main-white">
                  ปรับขนาดความกว้าง
                  <br />
                  ของรูปทรงบนหน้าจอ
                  <br />
                  ให้พอดีกับน้องชายของคุณ
                </span>
              </div>
              <CondomButton
                className="!min-h-[21vh] bg-measure-precisely active:bg-measure-precisely-click"
                onClick={() => {
                  close();
                }}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

function RoundedYellowButton(props: {
  text: string;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <div className="h-[70px] w-[70px] rounded-full border border-main-white ">
      <button
        {...props}
        className="h-[68px] w-[68px] rounded-full border-4 border-main-black bg-main-yellow p-6 text-center text-4xl font-bold leading-[0]"
      >
        {props.text}
      </button>
    </div>
  );
}
