import { useContext, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { MeasureContext } from '../../contexts/MeasureContext';
import CondomButton from '../commons/CondomButton';

export default function PickUpYourIdCard() {
  const { setProgress } = useContext(MeasureContext);
  const { nextStep } = useWizard();

  useEffect(() => {
    setProgress(25);
  }, []);

  return (
    <div className="main-bg h-full-exclude-progress">
      <div className="h-full p-4">
        <div className="container relative flex h-full flex-col bg-black-wide-condom bg-contain bg-center bg-no-repeat">
          <div className="flex flex-grow-0 flex-col pt-16">
            <div className="text-center text-xl font-bold text-main-yellow">
              ก่อนวัดน้องชาย
              <br />
              แวะตั้งค่าหน้าจอกันก่อน
            </div>
            <div className="mt-4 text-center text-xl font-light text-main-white">
              ขนาดหน้าจอมือถือแต่ละคนไม่เท่ากัน
              <br />
              กรุณาหยิบบัตรประชาชน
              <br />
              หรือบัตรอื่นๆ ที่มีขนาดเท่ากันขึ้นมา
              <br />
              เพื่อใช้เป็นไม้บรรทัดในการปรับ
              <br />
              ขนาดหน้าจอในขั้นตอนถัดไป
            </div>
          </div>
          <div className="flex flex-grow flex-col justify-end bg-assorted-condom bg-80% bg-bottom bg-no-repeat">
            <CondomButton
              className="bg-calibrate-screen active:bg-calibrate-screen-click"
              onClick={() => {
                nextStep();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
