import Slider from 'react-input-slider';

export default function VerticalSlider(props: {
  value: number;
  min: number;
  max: number;
  onChange: (px: number) => void;
}) {
  return (
    <div className="mr-10 flex h-full w-auto flex-col items-center justify-center gap-4 p-4">
      <div>
        <div className="h-[30px] w-[30px] rounded-full bg-main-yellow"></div>
      </div>
      <Slider
        axis="y"
        y={props.value}
        yreverse
        ymin={props.min}
        ymax={props.max}
        ystep={1}
        onChange={({ y }) => {
          props.onChange(y);
        }}
        styles={{
          track: {
            backgroundColor: 'rgb(255, 240, 0)',
            width: '3px',
            touchAction: 'none',
          },
          active: {
            backgroundColor: 'rgb(255, 240, 0)',
          },
          thumb: {
            width: 15,
            height: 15,
            backgroundColor: '#fff',
            borderWidth: 16,
            borderColor: '#ffffff99',
            borderStyle: 'solid',
            borderRadius: '9999px',
            touchAction: 'none',
          },
          disabled: {
            opacity: 0.5,
          },
        }}
      />
      <div>
        <div className="h-[15px] w-[15px] rounded-full bg-main-yellow"></div>
      </div>
    </div>
  );
}
