import { MeasureContextStateType } from '../contexts/MeasureContext';

export async function logResult(
  navigator: Navigator,
  measureContext: MeasureContextStateType,
) {
  await fetch('https://aixasz.com/api/GlowProject/RecordWatjuuu', {
    method: 'POST',
    body: JSON.stringify({
      userAgent: navigator.userAgent,
      size: measureContext.hisProductGroup?.size,
    }),
    headers: {
      'Content-Type': 'application/json',
    }
  });

}
