import { useContext, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { MeasureContext } from '../../contexts/MeasureContext';
import CondomButton from '../commons/CondomButton';

export default function WakeHimUp() {
  const { setProgress } = useContext(MeasureContext);
  const { nextStep } = useWizard();

  useEffect(() => {
    setProgress(50);
  }, []);

  return (
    <div className="wake-him-up-bg h-full-exclude-progress">
      <div className="h-full p-4 ">
        <div className="container relative flex h-full flex-col bg-black-wide-condom bg-contain bg-center bg-no-repeat">
          <div className="flex flex-grow flex-col justify-center text-center text-xl text-main-yellow">
            <div>
              <b>ปลุกน้องชายขึ้นมา</b>
              <br />
              <span className="font-light">ถึงเวลาวัดตัว</span>
            </div>
          </div>
          <div className="flex flex-grow flex-col justify-end bg-assorted-condom bg-80% bg-bottom bg-no-repeat">
            <div>
              <CondomButton
                className="!min-h-[18vh] bg-ready-to-measure active:bg-ready-to-measure-click"
                onClick={() => {
                  nextStep();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
