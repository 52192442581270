import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../components/Home/LoadingAnimation';
import { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CondomButton from '../components/commons/CondomButton';
import ScrollIndicator from '../components/commons/ScrollIndicator';

export default function HomePage() {
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="main-bg flex h-[100dvh] justify-center">
      {showLoading ? (
        <div className="container flex flex-col items-center justify-center">
          <div className="flex flex-grow items-center">
            <LoadingAnimation
              onCompleted={() => {
                setShowLoading(false);
              }}
            />
          </div>
          <div className="flex-grow-0 pb-12">
            <img
              className="mx-auto block w-[100px]"
              src="/img/home/onetouch-32.png"
              alt="onetouch"
            />
          </div>
        </div>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div
            className="h-full py-4"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.6,
            }}
          >
            <div
              ref={scrollContainerRef}
              className="container h-full snap-y snap-mandatory overflow-auto bg-black-wide-condom bg-contain bg-center bg-no-repeat"
            >
              <div className="mx-auto flex h-full w-7/12 snap-start flex-col justify-center pb-36">
                <div className="text-3xl font-bold leading-snug text-main-white">
                  เลือกให้ถูกไซซ์
                  <br />
                  เพื่อคนที่ใช่ของคุณ
                </div>
                <div className="mt-2 text-3xl font-bold text-main-yellow">
                  #SizeMatter
                </div>
              </div>

              <div className="mx-auto flex h-full w-7/12 snap-start flex-col justify-center pb-36">
                <div className="text-3xl font-bold leading-snug text-main-white">
                  ใส่ผิดไซซ์
                  <br />
                  เพิ่มสิทธิ์ท้อง
                  <br />
                  น้องชายหดตัว<sup className="text-main-yellow">*</sup>
                </div>
                <div className=" mt-2 text-main-yellow">
                  <sup>*</sup>การใช้ถุงยางอนามัยที่ไม่เหมาะสมอาจทำให้
                  <br />
                  สูญเสียการแข็งตัวของน้องชายได้ถึง 2 เท่า
                </div>
              </div>

              <div className="mx-auto flex h-full snap-start flex-col justify-between bg-assorted-condom bg-80% bg-bottom bg-no-repeat">
                <div className="mt-24">
                  <img
                    className="m-auto block w-[45%]"
                    src="/img/home/onetouch-36.png"
                    alt=""
                  />
                </div>

                <div className="pb-8">
                  <CondomButton
                    className="bg-start-measurement active:bg-start-measurement-click"
                    onClick={() => {
                      navigate('/measure');
                    }}
                  />
                </div>
              </div>
              <ScrollIndicator scrollContainerRef={scrollContainerRef} />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}
