import { Controller, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductGroup, Product } from '../../types/Product';
import 'swiper/css';
import { useState } from 'react';
import { appInsightsService } from '../../services/appInsightsService';

export default function ProductCarousel(props: {
  productGroup: ProductGroup;
  color: string;
  onChanged: (selected: Product) => void;
}) {
  // const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType>();

  return (
    <div className="container">
      <div className="mt-6 grid grid-flow-col gap-3 px-6">
        {props.productGroup.products.map((_, index) => {
          return (
            <div
              key={index}
              className="rounded-xl"
              style={{
                backgroundColor: `#${props.color}`,
                filter:
                  index !== swiperInstance?.activeIndex
                    ? 'brightness(60%)'
                    : '',
              }}
              onClick={() => {
                swiperInstance?.slideTo(index);
              }}
            >
              &nbsp;
            </div>
          );
        })}
      </div>
      <Swiper
        cssMode={true}
        modules={[Controller]}
        watchSlidesProgress
        initialSlide={0}
        spaceBetween={10}
        slidesPerView={1}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => {
          props.onChanged(props.productGroup.products[swiper.activeIndex]);
          // setActiveIndex(swiper.activeIndex);
        }}
      >
        {props.productGroup.products.map((o, index) => (
          <SwiperSlide key={index}>
            <div className="p-6 pb-0">
              <div
                className="min-h-[700px] w-full rounded-3xl px-4 py-8"
                style={{ backgroundColor: `#${props.color}` }}
              >
                <img className="p-10" src={o.imageUrl} alt={o.name.TH} />
                <div
                  className={`${
                    props.productGroup.themeColor === '440099'
                      ? 'text-white'
                      : ''
                  }`}
                >
                  <div className="whitespace-pre-line text-4xl font-bold">
                    {o.name.TH}
                  </div>
                  <div
                    className="whitespace-pre-line text-xl"
                    dangerouslySetInnerHTML={{ __html: o.description }}
                  ></div>
                  <div className="my-4 border-t border-t-gray-500"></div>
                  <div className="grid grid-cols-2 gap-2 text-xl">
                    <div>ขนาด / เส้นรอบวง</div>
                    <div className="text-right">
                      {props.productGroup.size} มม.
                    </div>
                    <div>พื้นผิว</div>
                    <div className="text-right">{o.surfaceType}</div>
                    <div>วัสดุ</div>
                    <div className="text-right">{o.material}</div>
                    <div>สีเนื้อยาง</div>
                    <div className="text-right">{o.latexColor}</div>
                  </div>
                  <div className="mt-6 mb-1 text-center text-xl font-bold">
                    สั้งซื้อเลย! ที่
                  </div>
                </div>

                <div className="flex flex-col gap-2 px-4">
                  <ExternalLinkButton
                    link={o.link.shopee}
                    color={props.color}
                    text="Shopee"
                  />
                  <ExternalLinkButton
                    link={o.link.lazada}
                    color={props.color}
                    text="Lazada"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

function ExternalLinkButton(props: {
  link: string;
  color: string;
  text: string;  
}) {

  const handleClick = () => {
    // Log the click event with App Insights
    appInsightsService.logEvent('ExternalLinkButtonClicked', { 
      link: props.link, 
      color: props.color, 
      text: props.text 
    });
  };

  return (
    <a
      href={props.link}
      target="_blank"
      className="block rounded-3xl bg-black p-0.5 text-center text-3xl font-bold"
      style={{
        color: `#${props.color}`,
      }}
      rel="noreferrer" 
      onClick={handleClick}
    >
      <span className="block rounded-3xl border border-main-white py-2">
        {props.text}
      </span>
    </a>
  );
}
