import { createContext, FC, ReactElement, Reducer, useReducer } from 'react';
import { Dimension } from '../types/Dimension';
import { Product, ProductGroup } from '../types/Product';
import { products } from '../data/series';
import { toIdCardWidth } from '../services/converter';

export type MeasureContextStateType = {
  progress: number;
  cardDimension: Dimension;
  hisSizeIndex: number;
  minHisSizeIndex: 0;
  maxHisSizeIndex: 5;
  hisProductGroup?: ProductGroup;
};

export type MeasureContextReturnType = {
  state: MeasureContextStateType;
  setProgress: (percentage: number) => void;
  setCardHeight: (h: number) => void;
  setHisSizeIndex: (w: MeasureContextStateType['hisSizeIndex']) => void;
  setProductGroup: (pg: ProductGroup) => void;
  setProduct: (p: Product) => void;
};

enum ActionKind {
  PROGRESS = 'PROGRESS',
  CARD_HEIGHT = 'CARD_HEIGHT',
  HIS_SIZE = 'HIS_SIZE',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
  PRODUCT = 'PRODUCT',
}

type Action =
  | {
      type: ActionKind.PROGRESS;
      payload: number;
    }
  | { type: ActionKind.CARD_HEIGHT; payload: number }
  | {
      type: ActionKind.HIS_SIZE;
      payload: MeasureContextStateType['hisSizeIndex'];
    }
  | { type: ActionKind.PRODUCT_GROUP; payload: ProductGroup }
  | { type: ActionKind.PRODUCT; payload: Product };

const defaultCardHeight = 300;

const initalState: MeasureContextStateType = {
  progress: 0,
  cardDimension: { w: toIdCardWidth(defaultCardHeight), h: defaultCardHeight },
  hisSizeIndex: 0,
  minHisSizeIndex: 0,
  maxHisSizeIndex: 5,
  hisProductGroup: products[0],
};

export const MeasureContext = createContext<MeasureContextReturnType>({
  state: initalState,
  setProgress: () => {},
  setCardHeight: () => {},
  setHisSizeIndex: () => {},
  setProductGroup: () => {},
  setProduct: () => {},
});

export const MeasureContextProvider: FC<{
  children: (state: MeasureContextStateType) => ReactElement<any, any> | null;
}> = (props) => {
  const [state, dispatch] = useReducer<
    Reducer<MeasureContextStateType, Action>
  >((state, action) => {
    const { type } = action;

    switch (type) {
      case ActionKind.PROGRESS:
        return {
          ...state,
          progress: action.payload,
        };
      case ActionKind.CARD_HEIGHT:
        const h = action.payload;

        return {
          ...state,
          cardDimension: {
            w: toIdCardWidth(h),
            h,
          },
        };
      case ActionKind.HIS_SIZE:
        return {
          ...state,
          hisSizeIndex: action.payload,
          hisProductGroup: products[action.payload],
        };
      case ActionKind.PRODUCT_GROUP:
        return {
          ...state,
          series: action.payload,
        };
      case ActionKind.PRODUCT:
        return {
          ...state,
          series: action.payload,
        };
      default:
        return state;
    }
  }, initalState);

  const setProgress = (percentage: number) => {
    dispatch({ type: ActionKind.PROGRESS, payload: percentage });
  };

  const setCardHeight = (h: number) => {
    dispatch({ type: ActionKind.CARD_HEIGHT, payload: h });
  };

  const setHisSizeIndex = (index: MeasureContextStateType['hisSizeIndex']) => {
    console.log(index);
    dispatch({ type: ActionKind.HIS_SIZE, payload: index });
  };

  const setProductGroup = (pg: ProductGroup) => {
    dispatch({ type: ActionKind.PRODUCT_GROUP, payload: pg });
  };

  const setProduct = (p: Product) => {
    dispatch({ type: ActionKind.PRODUCT, payload: p });
  };

  return (
    <MeasureContext.Provider
      value={{
        state,
        setProgress,
        setCardHeight,
        setHisSizeIndex,
        setProductGroup,
        setProduct,
      }}
    >
      {props.children(state)}
    </MeasureContext.Provider>
  );
};
