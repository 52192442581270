import { ProductGroup } from '../types/Product';

export const products: ProductGroup[] = [
  {
    size: 45,
    widthInMillimeter: 31.53,
    heightInMillimeter: 105.1,
    themeColor: 'ffc600',
    products: [
      {
        name: {
          TH: 'ซี๊ด',
          EN: 'ONETOUCH Zeed / ONETOUCH Zeed Condom',
        },
        description: `<b>ซี๊ดได้สุด ไม่มีหลุดแน่นอน</b>
        ถุงยางอนามัยขนาด 45 มม. ผิวเรียบ
        กล่องสีเหลือง`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.jYERw ',
          shopee: 'https://shopee.co.th/product/270094930/22646744297',
        },
        imageUrl: '/img/products/45-zeed.png',
      },
    ],
  },
  {
    size: 49,
    widthInMillimeter: 34.33,
    heightInMillimeter: 106.49,
    themeColor: '009b77',
    products: [
      {
        name: {
          TH: 'จอยส์',
          EN: 'ONETOUCH Joys Condom',
        },
        description: `<b>เอนจอยทุกครั้งที่ใส่</b>
          ถุงยางอนามัยขนาด 49 มม. ผิวเรียบ
          กล่องสีเขียว`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ชมพู',
        link: {
          lazada: 'https://s.lazada.co.th/s.86Z9U',
          shopee: 'https://shopee.co.th/tnr_official_store/13162931758',
        },
        imageUrl: '/img/products/49-joys.png',
      },
      {
        name: {
          TH: `ซีโร่ ซีโร่ ทรี 003
          ขนาด 49 มม.`,
          EN: `ONETOUCH Zero Zero Three 003
          size 49 mm Condom`,
        },
        description: `<b>แนบชิดถึงใจในไซซ์ 49</b>
          ถุงยางอนามัยแบบบางพิเศษเพียง 0.030
          ขนาด 49 มม. ผิวเรียบ`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.86ZRr',
          shopee: 'https://shp.ee/wcus4bg',
        },
        imageUrl: '/img/products/49-003.png',
      },
    ],
  },
  {
    size: 52,
    widthInMillimeter: 36.43,
    heightInMillimeter: 107.54,
    themeColor: 'da291c',
    products: [
      {
        name: {
          TH: 'แฮปปี้',
          EN: 'ONETOUCH Happy Condom',
        },
        description: `<b>เริ่มแฮปปี้ จบแฮปปี้</b>
          ถุงยางอนามัยยอดฮิตขนาด 52 มม.
          ผิวเรียบ กล่องสีแดง`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.860Fr',
          shopee: 'https://shopee.co.th/tnr_official_store/14023469081',
        },
        imageUrl: '/img/products/52-happy.png',
      },
      {
        name: {
          TH: 'มิกซ์ 3 พลัส',
          EN: 'ONETOUCH Mixx 3 Plus Condom',
        },
        description: `<b>เสริมลูกเล่นเน้นความนาน</b>
          ถุงยางอนามัยขนาด 52 มม. แบบปุ่มและขีด
          พร้อมสารเบนโซเคน ลดความไวต่อความรู้สึกสัมผัส`,
        surfaceType: 'ปุ่มและขีด',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.860wx',
          shopee: 'https://shp.ee/rc9ebxg',
        },
        imageUrl: '/img/products/52-mixx3.png',
      },
      {
        name: {
          TH: 'ซีโร่ ซีโร่ ทรี 003',
          EN: 'ONETOUCH Zero Zero Three 003 Condom',
        },
        description: `<b>บางถึงใจกับไซซ์ 52</b>
          ถุงยางอนามัยแบบบางพิเศษเพียง
          0.030 - 0.038 มม. ผิวเรียบ`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.86Yz6',
          shopee: 'https://shp.ee/32uh4mg',
        },
        imageUrl: '/img/products/52-003.png',
      },
      {
        name: {
          TH: 'สตรอเบอรี่',
          EN: 'ONETOUCH Strawberry Falvored Condom',
        },
        description: `<b>ยั่วยวนชวนลอง</b>
          ถุงยางอนามัยกลิ่นสตรอเบอรี่ หอมหวาน
          ขนาด 52 มม. ผิวเรียบ`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ชมพู',
        link: {
          lazada: 'https://s.lazada.co.th/s.86Zkn',
          shopee: 'https://shp.ee/krcb8fg',
        },
        imageUrl: '/img/products/52-strawberry.png',
      },
    ],
  },
  {
    size: 54,
    widthInMillimeter: 37.83,
    heightInMillimeter: 108.24,
    themeColor: 'ff7500',
    products: [
      {
        name: {
          TH: 'ดีไลท์',
          EN: 'ONETOUCH Delight Condom',
        },
        description: `<b>ดีต่อใจ ดีไลท์เลย</b>
          ถุงยางอนามัยขนาด 54 มม. ผิวเรียบ
          กล่องสีส้ม`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.jYEj6',
          shopee: 'https://shopee.co.th/tnr_official_store/22046741359',
        },
        imageUrl: '/img/products/54-delight.png',
      },
    ],
  },
  {
    size: 56,
    widthInMillimeter: 39.24,
    heightInMillimeter: 108.96,
    themeColor: '005eb8',
    products: [
      {
        name: {
          TH: 'เอ็กไซต์',
          EN: 'ONETOUCH Excite Condom',
        },
        description: `<b>คน Excite เค้าใส่กัน</b>
          ถุงยางอนามัยขนาด 56 มม.
          ผิวเรียบผนังขนาน กล่องสีน้ำเงิน`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.86Yxx',
          shopee: 'https://shopee.co.th/tnr_official_store/17202520653',
        },
        imageUrl: '/img/products/56-excite.png',
      },
    ],
  },
  {
    size: 60,
    widthInMillimeter: 42.04,
    heightInMillimeter: 110.4,
    themeColor: '440099',
    products: [
      {
        name: {
          TH: 'วันเดอร์',
          EN: 'ONETOUCH Wonderr Condom',
        },
        description: `<b>เอาอยู่ทุกความใหญ่</b>
          ถุงยางอนามัยขนาด 60 มม.
          ผิวเรียบผนังขนาน กล่องสีม่วง`,
        surfaceType: 'เรียบ',
        material: 'น้ำยางธรรมชาติ',
        latexColor: 'ธรรมชาติ',
        link: {
          lazada: 'https://s.lazada.co.th/s.jYEx5',
          shopee: 'https://shopee.co.th/tnr_official_store/17295159321',
        },
        imageUrl: '/img/products/60-wonderr.png',
      },
    ],
  },
];
