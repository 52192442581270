import { FC, PropsWithChildren } from 'react';

const ImageUploader: FC<
  PropsWithChildren<{
    onImageReady: (dataUrl: string) => void;
    className?: string;
  }>
> = ({ className, onImageReady, children }) => {
  const handleOnChange = (inputEvent: React.ChangeEvent<HTMLInputElement>) => {
    inputEvent.persist();

    const files = inputEvent.target.files;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const target = e.target as FileReader;
        onImageReady(target.result as string);

        // Reset input value to empty string so that
        // it doesn't persist through to new flow
        inputEvent.target.value = '';
      };

      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
      <label className={className}>
        {children}
        <input
          type="file"
          accept="image/jpeg, image/png"
          onChange={(e) => handleOnChange(e)}
          className="hidden"
        />
      </label>
    </>
  );
};

export default ImageUploader;
