import ReactProgressBar from '@ramonak/react-progress-bar';

export default function ProgressBar(props: { progress: number }) {
  return (
    <ReactProgressBar
      completed={props.progress}
      className="sticky top-0 left-0 z-50"
      bgColor="rgb(255, 240, 0)"
      baseBgColor="#000000"
      borderRadius="0"
      isLabelVisible={false}
    />
  );
}
